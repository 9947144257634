// select_toggle_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "source", "content", "templates" ];
  static classes = [ "toggle" ];
  static values = {
    toggle: String
  }

  connect() {
    this.toggle();
  }

  toggle() {
    let template = this.templatesTarget.querySelector(`template#${this.sourceTarget.value}`);
    let content = template.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf());
    this.contentTarget.innerHTML = content;
  }

}