import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    //available on the window through the packs
    //will need to be updated eventually
    initRangePicker($("div.range-picker"));
  }

}
